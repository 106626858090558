import { Routes } from "@angular/router";

export enum RouteKey {
  Root = "Root",
}

export const routes = {
  Root: "behold-din-livsstil",
};

export const needsLazyRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./needs-page.component").then((c) => c.NeedsPageComponent),
  },
];
